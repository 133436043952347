body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F8F9FA;
  padding-bottom: 2rem;
  
}
/* @media (prefers-color-scheme: dark) {
  body {
    background: #000;
  }

} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: 'Montserrat', sans-serif
}
.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(lato.woff) format('woff');
}